document.addEventListener("DOMContentLoaded", function (event) {
    function resizeForm() {
        var width = (window.innerWidth > 0) ? window.innerWidth : document.documentElement.clientWidth;
        if (width > 768) {
            var magnets = document.querySelectorAll(".magnetic");
            var strength = 100;

            magnets.forEach(magnet => {
                magnet.addEventListener("mousemove", moveMagnet);
                magnet.addEventListener("mouseout", function (event) {
                    TweenMax.to(event.currentTarget, 1, {
                        x: 0,
                        y: 0,
                        ease: Elastic.easeOut.config(1, 0.4)
                    });
                });
            });

            function moveMagnet(event) {
                var magnetButton = event.currentTarget;
                var bounding = magnetButton.getBoundingClientRect();

                TweenMax.to(magnetButton, 1, {
                    x: ((event.clientX - bounding.left) / magnetButton.offsetWidth - 0.5) * strength,
                    y: ((event.clientY - bounding.top) / magnetButton.offsetHeight - 0.5) * strength,
                    ease: Power4.easeOut
                });
            }
        }
    }
    window.onresize = resizeForm;
    resizeForm();
});


