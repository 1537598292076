const easing = Expo.easeInOut

TweenMax.from("nav .logo", 1, {
    y: 40,
    opacity: 0,
    ease: easing
});

TweenMax.staggerFrom("nav ul li", 1, {
    y: 40,
    opacity: 0,
    ease: easing
}, 0.2);

TweenMax.from(".svgAnim", 3, {
    delay: 1,
    opacity: 0,
    ease: easing
});

TweenMax.from("footer", 3, {
    delay: .7,
    opacity: 0,
    ease: easing
});